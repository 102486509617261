import config from '../config'
import moment from 'moment-timezone'

moment.locale('ru')

export default {
    created: function() {
        moment.tz.setDefault(this.$store.state.settings.tz_string)
        // this.hello()
    },
    methods: {
        
        getDate(days) {
            if (!days) days = 0
            if (moment().hours() < config.interface.hourNewDay) {
                days--
            }
            return moment().add(days, 'd')
        },
        getDate1String(days) {
            // относительно сегодняшнего дня (рабочей смены)
            return this.getDate(days).format('YYYY-MM-DD') + ' 0' + config.interface.hourNewDay + ':00:00'
        },
        getNullTimeDateString(days){
            return this.getDate(days).format('YYYY-MM-DD')
        },
        getDate2String(days) {
            // относительно сегодняшнего дня (рабочей смены)
            let hourNewDay = config.interface.hourNewDay
            if (hourNewDay == 0) {
                hourNewDay = 24
            }
            hourNewDay--
            if (!days) days = 0
            return this.getDate(days + 1).format('YYYY-MM-DD') + ' 0' + hourNewDay + ':59:59'
        },
        getDate1StringFromDate(date) {
            // абсолютное время
            return moment(date).add(config.interface.hourNewDay, 'h').format('YYYY-MM-DD HH:mm:ss')
        },
        getDate2StringFromDate(date) {
            // абсолютное время
            return moment(date).add(1, 'd').add(config.interface.hourNewDay, 'h').subtract(1, 's').format('YYYY-MM-DD HH:mm:ss')
        },
        timeMinutesHuman(timeMinutes, signFlag = false) {
            let time = ''
            let sign = '+'
            if (timeMinutes < 0) {
                sign = '-'
                timeMinutes = -timeMinutes
            }
            let mins = 0
            let hours = 0
            for (; timeMinutes >= 60; timeMinutes -= 60) {
                hours++
            }
            mins = timeMinutes
            if (signFlag) {
                time += sign
            }
            if (hours) {
                time += hours + ' ч '
            }
            // if (mins) {
                time += mins + ' м '
            // }
            return time
        },
        timeMinutesColorHtml(timeMinutes) {
            let color
            if (timeMinutes !== null) {
                if (timeMinutes >= 10) {
                    color = '#F44336' // red
                } else {
                    color = '#4CAF50' // green
                }
            } else {
                color = '#607D8B' // blue-grey
            }
            return color
        },
        timeDiff(time1, time2, signFlag = true) {
            let diff = ''
            if (time1 && time2) {
                diff = moment(time1).diff(time2, "minutes")
            }
            return this.timeMinutesHuman(diff, signFlag)
        },
        timeColorHtml(time1, time2) {
            let diff = null
            if (time1 && time2) {
                diff = moment(time1).diff(time2, "minutes")
            }
            return this.timeMinutesColorHtml(diff)
        },
        timeToString(date, todayShow = false) {
            const isToday = moment().isSame(moment(date), 'day')
            let dateRes = ''
            if (isToday) {
                if (todayShow) {
                    dateRes = 'сегодня '
                }
                dateRes += moment(date).format('HH:mm')
            } else {
                dateRes = moment(date).format('D MMM HH:mm')
            }
            if (dateRes == 'Invalid date') dateRes = ''
            return dateRes
        },
        isSameDate(_ref) {
            // взято тут https://github.com/JulianKingman/same-date
            // также можно использовать из репозитория: npm install same-date
            var d1 = _ref.date,
                d2 = _ref.compareDate,
                _ref$unit = _ref.unit,
                unit = _ref$unit === undefined ? 'day' : _ref$unit,
                _ref$sections = _ref.sections,
                sections = _ref$sections === undefined ? 1 : _ref$sections,
                _ref$local = _ref.local,
                local = _ref$local === undefined ? false : _ref$local
    
            var allowedUnits = ['hour', 'day', 'month', 'year']
            if (allowedUnits.indexOf(unit) < 0) throw 'Unit must be one of hour | day | month | year'
            var answer = true
            var config = [
                {
                    key: 'minute',
                    level: 1,
                    function: local ? 'getMinutes' : 'getUTCMinutes',
                    unitsPerSection: Math.ceil(60 / sections),
                },
                {
                    key: 'hour',
                    level: 1,
                    function: local ? 'getHours' : 'getUTCHours',
                    unitsPerSection: Math.ceil(60 / sections),
                },
                {
                    key: 'day',
                    level: 2,
                    function: local ? 'getDate' : 'getUTCDate',
                    unitsPerSection: Math.ceil(24 / sections),
                },
                {
                    key: 'month',
                    level: 3,
                    function: local ? 'getMonth' : 'getUTCMonth',
                    unitsPerSection: Math.ceil(new Date(d1.getUTCFullYear(), d1.getUTCMonth() + 1, 0).getUTCDate() / sections),
                },
                {
                    key: 'year',
                    level: 4,
                    function: local ? 'getFullYear' : 'getUTCFullYear',
                    unitsPerSection: Math.ceil(12 / sections),
                },
            ]
            var level = config.findIndex(function (f) {
                return f.key === unit
            })
            var i = config.length - 1
            for (; i >= level; i--) {
                // Otherwise just do the final check
                answer = answer && d1[config[i].function]() === d2[config[i].function]()
                // Last iteration, if multiple sections, check
                if (i === level && sections > 1) {
                    // It's the same so far, check if it falls in the same section
                    answer = Math.floor(d1[config[i - 1].function]() / config[i].unitsPerSection) === Math.floor(d2[config[i - 1].function]() / config[i].unitsPerSection)
                }
            }
            return answer
        },
        getSecondsStr(seconds, hide_sec = false) {
            let time = ''
            let years = 0
            let days = 0
            let hours = 0
            let mins = 0
            let l = 0
            let s = 0

            if (seconds >= 60) {
                if (seconds >= 3600) {
                    if (seconds >= 86400) {
                        if (seconds >= 31536000) {
                            years = parseInt(seconds / 31536000)
                            seconds -= years * 31536000
                            l = years.toString().slice(-1)
                            if (l == 1) {
                                time += years + ' год '
                            } else if (l > 0 && l < 5) {
                                time += years + ' года '
                            } else {
                                time += years + ' лет '
                            }
                        }
                        days = parseInt(seconds / 86400)
                        seconds -= days * 86400
                        l = days.toString().slice(-1)
                        if (days == 0) time += days + ' д. '
                        else if (days >= 11 && days <= 14) {
                            time += days + ' дней '
                        } else if (l == 1) {
                            time += days + ' день '
                        } else if (l > 0 && l < 5) {
                            time += days + ' дня '
                        } else {
                            time += days + ' дней '
                        }
                    }
                    hours = parseInt(seconds / 3600)
                    seconds -= hours * 3600
                    if (hours > 0 && years == 0 && days < 3) time += hours + ' ч. '
                }
                mins = parseInt(seconds / 60)
                seconds -= mins * 60
                if (years == 0 && days == 0 && mins > 0 && hours < 12) time += mins + ' мин. '
                if (!hide_sec) {
                    if (days == 0 && hours == 0 && mins < 30) {
                        s = parseInt(seconds)
                        if (s < 0) s = 0
                        if (s) {
                            time += s + ' сек.'
                        }
                    }
                }
            } else {
                if (hide_sec) time = 'менее минуты'
                else {
                    s = parseInt(seconds)
                    if (s < 0) s = 0
                    if (s) {
                        time += s + ' сек.'
                    }
                }
            }
            return time
        },
        getStringToDate(date, format){
            // Преобразует строку с датой date в объект Date формата format 
            return moment( new Date(date) ).format(format)
        },
    },
}
